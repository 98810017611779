var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import UniversityOrCityTypeahead from "./UniversityOrCityTypeahead.vue";
import LandingFeaturedCapitalCityListings from "./LandingFeaturedCapitalCityListings.vue";
import LandingFeaturedCapitalCities from "./LandingFeaturedCapitalCities.vue";
export default Vue.extend({
    components: {
        UniversityOrCityTypeahead: UniversityOrCityTypeahead,
        LandingFeaturedCapitalCities: LandingFeaturedCapitalCities,
        LandingFeaturedCapitalCityListings: LandingFeaturedCapitalCityListings,
    },
    apollo: {
        contentPage: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      {\n        contentPage(key: \"accommodation\") {\n          originalBannerImageUrl\n        }\n      }\n    "], ["\n      {\n        contentPage(key: \"accommodation\") {\n          originalBannerImageUrl\n        }\n      }\n    "]))),
    },
});
var templateObject_1;
