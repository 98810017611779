var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
export default Vue.extend({
    apollo: {
        capitalCitiesWithListings: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      {\n        capitalCitiesWithListings {\n          id\n          slug\n          name\n          capitalImageUrl\n        }\n      }\n    "], ["\n      {\n        capitalCitiesWithListings {\n          id\n          slug\n          name\n          capitalImageUrl\n        }\n      }\n    "]))),
    },
});
var templateObject_1;
