var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import PropertyCard from "./PropertyCard";
export default Vue.extend({
    components: {
        PropertyCard: PropertyCard,
    },
    data: function () {
        return {
            selectedIndex: 0,
        };
    },
    computed: {
        selectedCity: function () {
            if (!this.capitalCitiesWithLandingFeaturedListings) {
                return undefined;
            }
            return this.capitalCitiesWithLandingFeaturedListings[this.selectedIndex];
        },
    },
    apollo: {
        capitalCitiesWithLandingFeaturedListings: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      {\n        capitalCitiesWithLandingFeaturedListings {\n          id\n          slug\n          name\n          landingFeaturedListings {\n            id\n            ...PropertyCard_property\n          }\n        }\n      }\n      ", "\n    "], ["\n      {\n        capitalCitiesWithLandingFeaturedListings {\n          id\n          slug\n          name\n          landingFeaturedListings {\n            id\n            ...PropertyCard_property\n          }\n        }\n      }\n      ", "\n    "])), PropertyCard.options.fragments.property),
    },
});
var templateObject_1;
